<template>
  <input type="text" class="form-input w-full" v-model="query" placeholder="Search prints...">
</template>

<script>
import { defineComponent } from 'vue';
const debounce = require('lodash.debounce');

export default defineComponent({
  props: {
    initialQuery: String
  },
  data() {
    return {
      query: ""
    }
  },
  mounted() {
    this.query = this.initialQuery;
  },
  watch: {
    query: debounce((val) => {
      htmx.find("input[name=query]").value = val || ""; // eslint-disable-line no-undef
      htmx.trigger("#prints", 'refresh'); // eslint-disable-line no-undef
    }, 250)
  }
})
</script>