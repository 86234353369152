<template>
  <div class="relative text-gray-800">
    <a href="javascript:void(0)" v-on:click="toggleDropdown" class="inline-block align-baseline px-4 py-2 rounded hover:bg-gray-50">
      <span class="inline-block" v-if="selectedColour">
        <div class="inline-block items-center mr-4">
          <span
              v-for="(p, j) of selectedColour.colours"
              :key="j"
              class="inline-block w-3 h-3"
              :style="{ backgroundColor: p }"
          ></span>
        </div>

        {{ selectedColour.title }}

        <a v-if="selectedColour" @click="clearFilter" class="cursor-pointer text-teal-800 hover:text-red-700">
          <inline-svg class="inline-block align-middle w-7 h-7 ml-1" name="close.svg"></inline-svg>
        </a>
      </span>
      <slot v-else></slot>

      <svg class="inline-block ml-3 w-3" viewBox="0 0 11 8" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 8L0 0h11z" fill="#A2B9B9" fill-rule="evenodd"/>
      </svg>
    </a>

    <div v-show="dropdownVisible" class="absolute top-full z-30 right-0 mt-3 -mr-4 bg-white border border-gray-50 rounded shadow-lg w-40 xl:w-72">
      <ul class="py-2">
        <li
            v-for="(colour, i) of colours"
            :key="i"
            @click="selectColour(colour)"
            class="flex text-sm py-2 px-6 cursor-pointer hover:bg-teal-100"
        >
          <div class="flex items-center mr-4">
            <span
                v-for="(p, j) of colour.colours"
                :key="j"
                class="block w-3 h-3"
                :style="{ backgroundColor: p }"
            ></span>
          </div>
          {{ colour.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import InlineSvg from "@/components/Common/InlineSvg";

export default defineComponent({
  components: { InlineSvg },
  props: {
    data: {
      type: String,
      default() {
        return "[]"
      }
    },
    colour: String
  },
  data() {
    return {
      dropdownVisible: false,
      colours: [],
      selected: this.colour ? parseInt(this.colour) : null
    }
  },
  mounted() {
    document.addEventListener('click', this.close);

    this.colours = JSON.parse(decodeURIComponent(this.data));

    /*
    window.loadPrintsMasonry();

    htmx.onLoad(function() { // eslint-disable-line no-undef
      window.loadPrintsMasonry();
    });
    */
  },
  unmounted() {
    document.removeEventListener('click', this.close);
  },
  computed: {
    selectedColour() {
      return this.colours.find(c => c.id === this.selected);
    }
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },

    clearFilter() {
      this.selected = null;
      this.dropdownVisible = false;

      htmx.find(`input[name=colour]`).value = ''; // eslint-disable-line no-undef
      htmx.trigger("#prints", 'refresh'); // eslint-disable-line no-undef
    },

    selectColour(colour) {
      this.selected = colour.id;
      this.dropdownVisible = false;

      htmx.find("input[name=colour]").value = colour.id; // eslint-disable-line no-undef
      htmx.trigger("#prints", 'refresh'); // eslint-disable-line no-undef
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownVisible = false;
      }
    }
  }
});
</script>

<style scoped>

</style>