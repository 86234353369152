<template>
  <div class="relative text-gray-800">
    <a href="javascript:void(0)" v-on:click="toggleDropdown" class="inline-block align-baseline px-4 py-2 rounded hover:bg-gray-50">
      <slot></slot>

      {{ currentSearch.length > 0 ? currentSearch : term }}

      <a v-if="currentSearch.length > 0" @click="clearFilter" class="cursor-pointer text-teal-800 hover:text-red-700">
        <inline-svg class="inline-block align-middle w-7 h-7 ml-1" name="close.svg"></inline-svg>
      </a>

      <svg class="inline-block ml-3 w-3" viewBox="0 0 11 8" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 8L0 0h11z" fill="#A2B9B9" fill-rule="evenodd"/>
      </svg>
    </a>

    <div v-show="dropdownVisible" class="print-dropdown absolute top-full z-30 right-0 mt-3 -mr-4 bg-white border border-gray-50 rounded shadow-lg w-40 xl:w-64">
      <ul>
        <slot name="options" :filterBy="filterBy"></slot>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import InlineSvg from "@/components/Common/InlineSvg";

export default defineComponent({
  components: { InlineSvg },
  props: {
    term: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    initial: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dropdownVisible: false,
      currentSearch: this.initial
    }
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  unmounted() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownVisible = false;
      }
    },

    clearFilter() {
      this.currentSearch = '';
      this.dropdownVisible = false;

      htmx.find(`input[name=${this.field}]`).value = ''; // eslint-disable-line no-undef
      htmx.trigger("#prints", 'refresh'); // eslint-disable-line no-undef
    },

    filterBy(id, title) {
      this.currentSearch = title;
      this.dropdownVisible = false;

      htmx.find(`input[name=${this.field}]`).value = id; // eslint-disable-line no-undef
      htmx.trigger("#prints", 'refresh'); // eslint-disable-line no-undef
    }
  }
});
</script>

<style>
.print-dropdown ul {
  @apply py-2;

  max-height: 200px;
  overflow-y: scroll;
}

.print-dropdown ul a {
  @apply flex text-sm py-1.5 px-6 cursor-pointer hover:bg-teal-100;
}

.print-dropdown li.divider {
  @apply border-t border-gray-100 my-2;
}

.print-dropdown .clear-filter {
  @apply italic;
}
</style>