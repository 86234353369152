import { h } from 'vue';

class Svg {
    constructor(name) {
        let loaded = require(`!raw-loader!../../svgs/${name}`);

        if (loaded && loaded.default) {
            let div = document.createElement('div');
            div.innerHTML = loaded.default;

            let fragment = document.createDocumentFragment();
            fragment.appendChild(div);

            this.svg = fragment.querySelector('svg');
        }
    }

    classes(classes) {
        if (classes && this.svg) {
            this.svg.classList.add(classes);
        }

        return this;
    }

    width(width) {
        if (width && this.svg) {
            this.svg.setAttribute('width', width);
        }

        return this;
    }

    height(height) {
        if (height && this.svg) {
            this.svg.setAttribute('height', height);
        }

        return this;
    }

    toString() {
        if (this.svg) {
            return this.svg.outerHTML;
        } else {
            return '';
        }
    }
}

const component = {
    props: ['name', 'classes', 'width', 'height'],

    render() {
        return h('div', {
            innerHTML: new Svg(this.name)
                .classes(this.classes)
                .width(this.width)
                .height(this.height)
        });
    }
};

export default component;
