<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" static :open="isOpen" @close="setIsOpen">
        <div class="fixed inset-0 z-10 overflow-y-auto bg-opacity-50 bg-black">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
            >
              <div
                  class="book-modal relative inline-block w-full p-4 mt-16 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl"
              >
                <button @click="setIsOpen(false)" class="absolute focus:outline-none top-4 right-4 text-gray-600">
                  <inline-svg class="w-8 h-8" name="close.svg" />
                </button>

                <iframe class="w-full h-full" :src="link" frameborder="0"></iframe>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <slot :setIsOpen="setIsOpen"></slot>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import InlineSvg from "../components/Common/InlineSvg";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    InlineSvg,
  },
  props: {
    link: {
      type: String,
      default: ''
    }
  },
  setup() {
    const isOpen = ref(false);

    return {
      isOpen,
      setIsOpen(value) {
        isOpen.value = value;

        try {
          gtag('event', "open_workshop_modal");
        } catch(e) {
          console.error(e, e.stack);
        }
      }
    };
  },
};
</script>

<style scoped>
.book-modal {
  @apply max-w-6xl;
  height: 85vh;

  @screen lg {
    height: 70vh;
  }
}
</style>