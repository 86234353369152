import "./styles/app.css";
import 'alpinejs';

const trackEvent = function(action, params = {}) {
  try {
    gtag('event', action, params);
  } catch (e) {
    console.error(e, e.stack);
  }
}

//
// Contact Overlay
//

window.toggleContact = function (visible = null) {
  const contactOverlayUnderlay = document.querySelector(".contact-overlay__underlay");
  const contactOverlay = document.querySelector(".contact-overlay");

  const oldState = contactOverlayUnderlay.classList.contains('contact-overlay--open');
  const newState = (visible === null) ? !oldState : visible;

  if (newState === true) {
    window.toggleNav(false);

    contactOverlayUnderlay.style.visibility = 'visible';
    contactOverlay.style.visibility = 'visible';

    contactOverlayUnderlay.classList.add("contact-overlay--open");
    contactOverlay.classList.add("contact-overlay--open");

    contactOverlay.classList.remove("contact-overlay--close");
    contactOverlay.classList.add("contact-overlay--open");
  } else {
    contactOverlayUnderlay.classList.remove("contact-overlay--open");
    contactOverlay.classList.remove("contact-overlay--open");

    contactOverlay.classList.remove("contact-overlay--open");
    contactOverlay.classList.add("contact-overlay--close");
  }

  if (newState) {
    trackEvent("open_contact_overlay");
  }

  /*
    if (globalNav.classList.contains('global-menu--open')) {
      globalNav.classList.remove("global-menu--open");
      globalNav.classList.add("global-menu--close");
    } else {
      globalNav.classList.remove("global-menu--close");
      globalNav.classList.add("global-menu--open");
    }*/
};



const readMore = document.querySelector(".read-more");
if (readMore) {
  readMore.addEventListener('click', () => {
    const splash = document.querySelector(".diag-splash");
    splash.classList.toggle("diag-splash--expand-content");
  });
}

//
// Nav
//

window.toggleNav = function (visible = null) {
  const menuIcon = document.querySelector(".mobile-menu-icon");
  const globalUnderlay = document.querySelector(".global-menu__underlay");
  const globalNav = document.querySelector(".global-menu");

  const oldState = globalUnderlay.classList.contains("global-menu--open");
  const newState = (visible === null) ? !oldState : visible;

  if (newState === true) {
    window.toggleContact(false);

    globalUnderlay.style.visibility = 'visible';
    globalNav.style.visibility = 'visible';

    globalNav.classList.remove("global-menu--close");
    globalNav.classList.add("global-menu--open");

    menuIcon.classList.add("open");
    globalUnderlay.classList.add("global-menu--open");
  } else {
    globalNav.classList.remove("global-menu--open");
    globalNav.classList.add("global-menu--close");

    menuIcon.classList.remove("open");
    globalUnderlay.classList.remove("global-menu--open");
  }
};

const navToggle = document.querySelector(".nav-toggle");
if (navToggle) {
  navToggle.addEventListener('click', () => {
    window.toggleNav();
  });
}

document.querySelectorAll("a[href^='#contact']").forEach((el) => [
  el.addEventListener('click', (e) => {
    window.toggleContact();
    e.stopPropagation();
    e.preventDefault();
  })
]);


window.addEventListener('scroll', function() {
  const SCROLL_THRESHOLD = 400;
  const BODY_STICKY_CLASS = "header--stick";

  const body = document.querySelector("body");

  if (window.scrollY > SCROLL_THRESHOLD) {
    if (!body.classList.contains(BODY_STICKY_CLASS)) {
      body.classList.add(BODY_STICKY_CLASS);
    }
  } else {
    if (body.classList.contains(BODY_STICKY_CLASS)) {
      body.classList.remove(BODY_STICKY_CLASS);
    }
  }
});


import './vendor/fullpage.fadingEffect.min';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded'
import 'lightgallery.js';
import 'lg-share.js';
import 'lg-fullscreen.js';
import 'lg-video.js';
import AOS from 'aos';
import reframe from "reframe.js";

reframe(".responsive-video");
reframe(".pb-formattedText figure iframe");

const hideLoading = () => {
  const elements = document.querySelectorAll(".lds-ring");
  Array.prototype.forEach.call(elements, function(el) {
    el.style.display = 'none';
  });
}

if (document.getElementById("gallery")) {
  /*createApp(Gallery)
    .use(VueMasonryPlugin)
    .mount('#gallery');*/

  const grid = document.getElementById("gallery");

  imagesLoaded( grid, function() {
    hideLoading();
    grid.style.display = "";

    // init Isotope after all images have loaded
    const masonryGrid = new Masonry( grid, {
      columnWidth: '.grid-sizer',
      itemSelector: '.masonry-item',
      percentPosition: true
    });

    setTimeout(function () {
      masonryGrid.layout();
    }, 500);
  });

  // eslint-disable-next-line no-undef
  lightGallery(grid, {
    selector: '.masonry-item a'
  });
}

if (document.getElementById("travel-archive")) {
  const grid = document.getElementById("travel-archive");

  imagesLoaded( grid, function() {
    hideLoading();
    grid.style.display = "";

    // init Isotope after all images have loaded
    const masonryGrid = new Masonry( grid, {
      columnWidth: '.grid-sizer',
      itemSelector: '.masonry-item',
      percentPosition: true
    });

    setTimeout(function () {
      masonryGrid.layout();
    }, 500);
  });
}

/*
window.loadPrintsMasonry = function() {
  const grid = document.querySelector("#prints .prints-grid");

  imagesLoaded( grid, function() {
    hideLoading();
    grid.style.display = "";

    // init Isotope after all images have loaded
    const masonryGrid = new Masonry( grid, {
      columnWidth: '.grid-sizer',
      itemSelector: '.masonry-item',
      percentPosition: true
    });

    setTimeout(function () {
      masonryGrid.layout();
    }, 500);

    window.printMasonryGrid = masonryGrid;
  });
}

if (document.getElementById("prints")) {
  window.loadPrintsMasonry();
}
*/

/*
import Prints from './components/Prints';

if (document.getElementById("prints")) {
}
*/

if (document.getElementById("fullpage")) {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  let titles = [];

  document.querySelectorAll("#fullpage > div").forEach(e => {
    titles.push(e.getAttribute("data-title"));
  });

  new fullpage('#fullpage', {
    licenseKey: "26DD522B-EB254050-A6CAF8D7-C2EEBA34",
    //verticallyCentered: true,
    css3: true,
    navigation: true,
    navigationPosition: 'right',
    showActiveTooltip: screenWidth >= 1024,
    navigationTooltips: titles,

    fadingEffect: true,
    fadingEffectKey: "bWF0dGhld3dpbGxpYW1zLWVsbGlzLmNvbV9DN2habUZrYVc1blJXWm1aV04wWXgy"
  });
}

import Flickity from 'flickity-fade';
import { tns } from 'tiny-slider/src/tiny-slider';

if (document.getElementById("home-carousel")) {
  const container = document.getElementById("home-carousel");

  // let speed = parseInt(container.getAttribute("data-speed")) || 400;
  let timeout = parseInt(container.getAttribute("data-timeout")) || 5000;

  new Flickity(container, {
    fade: true,
    autoPlay: timeout,
    pauseAutoPlayOnHover: false,
    lazyLoad: 1,
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false
  })

/*
  tns({
    container: "#home-carousel",
    mode: "gallery",
    autoplay: true,
    lazyload: true,
    speed: speed,
    autoplayTimeout: timeout,
    nav: false,
    controls: false,
    autoplayButtonOutput: false
  })*/
}

[].forEach.call(document.querySelectorAll(".print-collection-slider"), function (el) {
  tns({
    mode: 'carousel',
    container: el,
    slideBy: 'page',
    items: 1,
    mouseDrag: true,
    lazyload: true,
    speed: 600,
    controlsContainer: el.previousElementSibling,
    responsive: {
      640: {
        items: 2
      },
      768: {
        items: 3
      },
      1024: {
        items: 4
      }
    }
  })
});

[].forEach.call(document.querySelectorAll(".image-slider"), function (el) {
  tns({
    mode: 'carousel',
    container: el,
    slideBy: 'page',
    items: 1,
    mouseDrag: true,
    lazyload: true,
    speed: 600,
    controlsContainer: el.previousElementSibling,
    responsive: {
      640: {
        items: 2
      },
      1024: {
        items: 3
      }
    }
  });

  // eslint-disable-next-line no-undef
  lightGallery(el, {
    selector: '.image-slider a'
  });
});

[].forEach.call(document.querySelectorAll(".quote-slider"), function (el) {
  tns({
    mode: 'carousel',
    container: el,
    slideBy: 'page',
    items: 1,
    controls: true,
    nav: true,
    mouseDrag: true,
    lazyload: true,
    autoplay: true,
    speed: 500,
    autoplayButtonOutput: false
  })
});

window.loadPrintsSlider = function() {
  tns({
    container: "#print-slider",
    controls: false,
    navContainer: "#print-slider-controls",
    navAsThumbnails: true
  });
}

if (document.getElementById("print-slider")) {
  window.loadPrintsSlider();
}

document.querySelector(".contact-overlay select[name='message[Interest]']").addEventListener("change", function() {
  const option = this.options[this.selectedIndex];
  const hint = document.querySelector(".contact-overlay__hint");

  hint.innerHTML = option.getAttribute("data-hint");
})

AOS.init({
  disable: "mobile"
});

import Rellax from 'rellax';
Rellax('.rellax');

window.addEventListener('load', AOS.refresh);

import { lazyLoadModule } from './modules/lazyLoad';
lazyLoadModule();

import { cookiePolicy } from "@/modules/cookiePolicy";
cookiePolicy(() => {
  if (window.gtmInit) {
    window.gtmInit();
  }
});

import { createApp } from 'vue';

import ColourPaletteDropdown from './components/ColourPaletteDropdown.vue';
import PrintSearchDropdown from "./components/PrintSearchDropdown.vue";
import PrintSearch from "./components/PrintSearch.vue";
import BookWorkshopModal from "./components/BookWorkshopModal.vue";

if (document.getElementById("prints-app")) {
  createApp({
    components: {
      ColourPaletteDropdown,
      PrintSearchDropdown,
      PrintSearch
    }
  }).mount('#prints-app')
}

document.querySelectorAll(".book-workshop").forEach((el) => {
  createApp({
    components: {
      BookWorkshopModal
    }
  }).mount(el)
});

const submitContactForm = function (e) {
  e.preventDefault();

  var elements = document.getElementsByClassName("formVal");
  var formData = new FormData();
  for (var i = 0; i < elements.length; i++) {
    formData.append(elements[i].name, elements[i].value);
  }
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function() {
    if(xmlHttp.readyState == 4 && xmlHttp.status == 200)
    {
      alert(xmlHttp.responseText);
    }
  }
  xmlHttp.open("post", "/");
  xmlHttp.send(formData);
};

import * as $ from 'jquery';

$('.contact-form').submit(function(ev) {
  // Prevent the form from actually submitting
  ev.preventDefault();

  // Send it to the server
  $.post({
    url: '/',
    dataType: 'json',
    data: $(this).serialize(),
    success: function(response) {
      if (response.success) {
        $('.contact-form').hide();
        $('.contact-form-errors').hide();
        $('.contact-form-thanks').text("Thank you for your message. I'll be in touch soon.").fadeIn();
      } else {
        var message = "";
        for (const key in response.errors) {
          message += " " + response.errors[key].join(", ");
        }

        $('.contact-form-thanks').hide();
        $('.contact-form-errors').text(message).fadeIn();
      }

      setTimeout(function () {
        window.toggleContact(false);
        $('.contact-form-errors').fadeOut();
        $('.contact-form-thanks').fadeOut();
        $('.contact-form').show();
      }, 4000);
    }
  });
});

$(document).ready(function () {
  if (window.location.hash === "#contact") {
    setTimeout(function () {
      window.toggleContact();
    }, 200);
  }
});

/*
  Attempt to stop Snipcart and HTMX from clashing with one another
 */
// eslint-disable-next-line no-undef
htmx.on('htmx:historyRestore', function(event) {
  (function () {
    window.location.reload();
  })();
});

/*
  Sync basket icon to Snipcart
 */
document.addEventListener('snipcart.ready', () => {
  const cartButton = document.querySelector(".snipcart-checkout");
  const snipcartState = Snipcart.store.getState();

  const cartItems = snipcartState.cart.items.count;

  if (cartItems > 0) {
    cartButton.classList.add("md:block");
  }

  // eslint-disable-next-line
  Snipcart.events.on('snipcart.initialized', (snipcartState) => {
    const cartItems = snipcartState.cart.items.count;

    if (cartItems > 0) {
      cartButton.classList.add("md:block");
    }
  });

  // eslint-disable-next-line
  Snipcart.events.on('item.added', function(item) {
    cartButton.classList.add("md:block");
  });
});
